import React, { useEffect, useState } from 'react'

export default function Parser(props){
    return(
       <> 
       {props.text.split('<br/>').map(elem=>{
           return <p className="text12">{elem}</p>
       })}
       </>
    )
}