import React from 'react'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1.5
  },
  tablet: {
    breakpoint: { max: 1024, min: 401 },
    items: 1.5
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1.4
  }
};




export default function en() {
  const openInNewTab = () => {
    if (window.generateUrl === null) {
      const newWindow = window.open(window.location.href, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } else {
      const newWindow = window.open(window.generateUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }
  return (
    <div className="App">
      <img src="/img/mainEn.png" style={{ width: '100%', position: 'absolute', zIndex: -1, marginTop: 56 }} />
      <header>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/img/logo.png" style={{ width: '114px',marginLeft:16}} onClick = {()=>openInNewTab()} />
        </div>
        <div>
          <button className="button" onClick = {()=>openInNewTab()} >Get started</button>
        </div>
      </header>
      <button className="button3" style={{marginTop:'140%'}} onClick = {()=>openInNewTab()} >Customize your story</button>
      <div style={{width:'80%',marginTop:'208%',display:'flex',justifyContent:'flex-end'}}>
        <button className="button3" style={{width:'85%'}} onClick = {()=>openInNewTab()} >Read now</button>
      </div>
      <div style={{width:'80%',marginTop:'160%',display:'flex',justifyContent:'flex-end'}}>
        <button className="button3" style={{width:'85%'}} onClick = {()=>openInNewTab()} >Read now</button>
        
      </div>
      <video width="100%" autoPlay muted loop controls style={{marginTop:'26%'}}>
      <source src="./chess.mp4" type="video/mp4"/>
     </video>
      <button className="button3 button6" style={{width:'80%',marginTop:'10%'}} onClick = {()=>openInNewTab()} >Play now</button>
      <p style={{ width: '90%' ,marginTop:'10%'}} className="text">User Reviews</p>
      <div className='multicarousel' style={{ maxWidth: window.screen.width ,marginTop:30}}>
        <Carousel responsive={responsive} arrows={false} showDots slidesToSlide={1}>
          <img className='slider__img' src="/img/feedbacks/feedback1.png" />
          <img className='slider__img' src="/img/feedbacks/feedback2.png" />
          <img className='slider__img' src="/img/feedbacks/feedback3.png" />
          <img className='slider__img' src="/img/feedbacks/feedback4.png" />
          <img className='slider__img' src="/img/feedbacks/feedback5.png" />
        </Carousel>
      </div>
      <img src="/img/app.png" style={{ width: '60%', maxWidth: '50%',height:'auto', marginTop: '100%' }} onClick = {()=>openInNewTab()} />

      
    </div>
  );
}