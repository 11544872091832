import React, { useEffect, useState } from 'react'
import queryString from 'query-string';
import Parser from './parser';
import './back.css'

const data = [
    {
        keyword:'chat',
        text:"Сhat with your love interest. Build your relationship. Create your own story you really deserve.",
        img:'chat.jpeg'
    },
    {
        keyword:'live_photo',
        text:`Running from your abusive ex, you didn't even imagine that the only place you can hide in, would be a BDSM & kink club in the other city. And moreover, you couldn't have foreseen yourself falling for it's mysterious ravishing owner - Gideon Reyes. Now you're being torn between your feelings and your fears.`,
        img:'live_photo.jpeg'
    },
    {
        keyword:'titanic',
        text:"Be a main character and explore new feelings in a real Love Story like Titanic.",
        img:'titanic.jpeg'
    },
    {
        keyword:'mask',
        text:`Explore something new in "My Fantasy"`,
        img:'mask.jpeg'
    },
    {
        keyword:'stories_choose',
        text:'- Customise your character👠👗👜.<br/>- Build relationship with your love interest👩‍❤️‍👨.<br/>- Choose a story and immerse yourself into your most desired fantasy👄😍',
        img:'stories_choose.jpeg'
    },
    {
        keyword:'titanic-2',
        text:'Be a main character and explore new feelings in a real Love Story like Titanic.',
        img:'titanic-2.jpeg'
    },
    {
        keyword:'horse-riding',
        text:`Explore something new in "My Fantasy"`,
        img:'horse-riding.jpeg'
    },
    {
        keyword:'deadpool',
        text:`Explore something new in "My Fantasy"`,
        img:'deadpool.jpeg'
    },
    {
        keyword:'rabbit_girl',
        text:`Explore something new in "My Fantasy"`,
        img:'rabbit_girl.jpeg'
    },
    {
        keyword:'mmy_1',
        text:'- Customise your character👠👗👜.<br/>- Build relationship with your love interest👩‍❤️‍👨.<br/>- Choose a story and immerse yourself into your most desired fantasy👄😍',
        img:'mmy_1.jpeg'
    },
    {
        keyword:'mmy_2',
        text:'- Customise your character👠👗👜.<br/>- Build relationship with your love interest👩‍❤️‍👨.<br/>- Choose a story and immerse yourself into your most desired fantasy👄😍',
        img:'mmy_2.jpeg'
    },
    {
        keyword:'mmy_3',
        text:'- Customise your character👠👗👜.<br/>- Build relationship with your love interest👩‍❤️‍👨.<br/>- Choose a story and immerse yourself into your most desired fantasy👄😍',
        img:'mmy_3.jpeg'
    },
    {
        keyword:'touch',
        text:`Explore something new in "My Fantasy"`,
        img:'touch.jpeg'
    },
    {
        keyword:'bed',
        text:`Explore something new in "My Fantasy"`,
        img:'bed.jpeg'
    }
]



export default function Short1(props) {
    const openInNewTab = () => {
        if (window.generateUrl === null) {
            const newWindow = window.open(window.location.href, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else {
            const newWindow = window.open(window.generateUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }
    const [campaign, setCampaign] = useState(0)
    const [campaignName, setCampaignName] = useState('')
    const [image, setImage] = useState('')
    useEffect(() => {
        const parsed = queryString.parse(props.location.search);
        setImage(parsed.original_url_ad)
        data.forEach((elem,index)=>{
            console.log(elem)
            console.log(parsed.utm_campaign)
            if(parsed.utm_campaign.includes(elem.keyword)){
                setCampaign(index)
            }
        })
    }, [])
    return (
        <>
            {/* <img src="/img/multilanding.png" style={{ width: '100%', maxWidth: 500 }} /> */}
            <div className="bggg" style={{ width: '100%', height: "100%",display:'flex',flexDirection:'column',alignItems:'center',maxWidth:500 }}>

                <header style={{ background: '#0E0F10' ,marginLeft:"auto",left:0,marginRight:"auto",right:0}}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="/img/logo.png" style={{ width: '114px', marginLeft: 16 }} onClick={() => openInNewTab()} />
                    </div>
                    <div>
                        <button className="button" style={{ background: 'linear-gradient(180deg, #DB5186 0%, #C6236A 100%)', fontWeight: 700 }} onClick={() => openInNewTab()} >Download for IOS</button>
                    </div>
                </header>
                <p className="text11">Play #1 Interactive Stories Game<br/> <img src='/img/emoji.png' style={{height:32}}/></p>
                <img src={`/img/multi/${data[campaign].img}`} style={{width:'90%',marginBottom:10,borderRadius:20}}/>
                <Parser text={data[campaign].text}/>
                {/* <p className="text13">with the most desired man in your life.</p> */}
                <div style={{ width: '100%', display: 'flex',paddingBottom:15,marginTop:15,flexDirection: 'column', justifyContent: 'center', alignItems: 'center' ,background:'rgba(74, 82, 92, 0.5)',borderRadius:'40px 40px 0px 0px'}}>
                    
                    <button style={{ background: "linear-gradient(180deg, #DB5186 0%, #C6236A 100%)", border: '2px solid rgba(255, 255, 255, 0.2)', borderRadius: 30, height: 50, width: '90%', marginBottom: 30,marginTop:20, maxWidth:450,fontFamily: 'Nunito', color: 'white', fontWeight: 700, fontSize: 16, textAlign: "center" }}onClick={() => openInNewTab()}>Play now</button>
                    <img src="/img/app.png" style={{ width: 120}} onClick={() => openInNewTab()}/>

                </div>

            </div>
</>
    )
}