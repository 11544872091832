import logo from './logo.svg';
import './App.css';
import { Switch, Route } from 'react-router-dom'
import en from './components/en'
import en2 from './components/en2'
import Short1 from './components/short1';
import Short2 from './components/short2';
import Short3 from './components/short3'
import Multilanding from './components/multilanding'

function App() {
  return (
    <Switch>
      <Route path='/' component={en} exact/>
      <Route path='/new' component={en2} exact/>
      <Route path='/short1' component={Short1} exact />
      <Route path='/short2' component={Short2} exact />
      <Route path='/short3' component={Short3} exact />
      <Route path='/multilanding' component={Multilanding} exact />
    </Switch>
  )

}

export default App;

