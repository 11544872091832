import React from 'react'

export default function Short1() {
    const openInNewTab = () => {
        if (window.generateUrl === null) {
            const newWindow = window.open(window.location.href, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else {
            const newWindow = window.open(window.generateUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }
    return (
        <>
            <img src="/img/short2.png" style={{ width: '100%', maxWidth: 500 }} />
            <div style={{ width: '100%', height: "100%", position: "absolute" }}>

                <header style={{ background: '#0E0F10',marginLeft:"auto",left:0,marginRight:"auto",right:0 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="/img/logo.png" style={{ width: '114px', marginLeft: 16 }} onClick={() => openInNewTab()} />
                    </div>
                    <div>
                        <button className="button" style={{ background: 'linear-gradient(180deg, #DB5186 0%, #C6236A 100%)', fontWeight: 700 }} onClick={() => openInNewTab()} >Download for IOS</button>
                    </div>
                </header>
                <div style={{ position: 'absolute', bottom: 0, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <button style={{ background: "linear-gradient(180deg, #DB5186 0%, #C6236A 100%)", border: '2px solid rgba(255, 255, 255, 0.2)', borderRadius: 30,maxWidth:450, height: 50, width: '90%', marginBottom: 30, fontFamily: 'Nunito', color: 'white', fontWeight: 700, fontSize: 16, textAlign: "center" }}onClick={() => openInNewTab()}>Play now</button>
                    <img src="/img/app.png" style={{ width: 120, marginBottom: 15 }} onClick={() => openInNewTab()}/>

                </div>

            </div>

        </>
    )
}